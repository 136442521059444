import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
// import ReactTooltip from 'react-tooltip';

const Percentage = styled.div`
  margin-left: 4px;
`;

const Container = styled.button<{
  color: string;
  alignment?: string;
  isLoading?: boolean;
  noPadLoad?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  height: 36px;
  border: 0;
  outline: 0;
  flex-grow: 0;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  transition: ${({ theme }) => theme.transitions.default};
  div {
    display: flex;
    margin-right: ${({ theme, noPadLoad }) => (noPadLoad ? 0 : theme.spacing.xs)};
  }
  ${({ alignment }) => {
    switch (alignment) {
      case 'center': {
        return 'margin: auto';
      }
      case 'right': {
        return 'margin-left: auto';
      }
    }
  }};
  ${({ color, theme }) => {
    if (color === 'red') {
      return `border 1px solid ${theme.colors.danger}`;
    }
    if (color === 'primary') {
      return `border 1px solid ${theme.colors.dark}`;
    }
    if (color === 'disabled') {
      return `border 1px solid ${theme.colors.grey}`;
    }
    if (color === 'dark') {
      return `border 1px solid ${theme.colors.dark}`;
    }
    if (color === 'outline') {
      return `border 1px solid ${theme.colors.dark}`;
    }
  }};
  padding: ${({ theme }) => theme.spacing.md};
  transition: ${({ theme }) => theme.transitions.default};
  background-color: ${({ color, theme }) => {
    switch (color) {
      case 'primary':
        return theme.colors.dark;
      case 'outline':
        return theme.colors.white;
      case 'red':
        return theme.colors.danger;
      case 'green':
        return theme.colors.success;
      case 'dark':
        return theme.colors.dark;
      case 'disabled':
        return theme.colors.grey;
      default:
        throw Error(`Unsupported button color: ${color}`);
    }
  }};
  color: ${({ color, theme }) => {
    switch (color) {
      case 'primary':
        return theme.colors.white;
      case 'outline':
        return theme.colors.dark;
      case 'red':
        return theme.colors.white;
      case 'green':
        return theme.colors.primary;
      case 'dark':
        return theme.colors.white;
      case 'disabled':
        return theme.colors.light;
      default:
        throw Error(`Unsupported button color: ${color}`);
    }
  }};

  &:hover {
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ color, theme }) => {
      switch (color) {
        case 'primary':
          return theme.colors.primary;
        case 'outline':
          return theme.colors.dark;
        case 'red':
          return theme.colors.danger;
        case 'green':
          return theme.colors.success;
        case 'dark':
          return theme.colors.dark;
        case 'disabled':
          return theme.colors.grey;
        default:
          throw Error(`Unsupported button color: ${color}`);
      }
    }};
    color: ${({ color, theme }) => {
      switch (color) {
        case 'primary':
          return theme.colors.white;
        case 'outline':
          return theme.colors.white;
        case 'red':
          return theme.colors.white;
        case 'green':
          return theme.colors.success;
        case 'dark':
          return theme.colors.white;
        case 'disabled':
          return theme.colors.light;
        default:
          throw Error(`Unsupported button color: ${color}`);
      }
    }};
  }
`;

type Props = {
  alignment?: string;
  color: string;
  handler?: () => void;
  link?: string;
  isLoading?: boolean;
  noPadLoad?: boolean;
  loaderSize?: number;
  percentage?: number;
  children: any;
  disabled?: boolean;
  tooltip?: string;
  style?: any;
};

const Button = ({
  alignment,
  color,
  handler,
  link,
  isLoading,
  noPadLoad,
  loaderSize,
  percentage,
  disabled,
  tooltip,
  style,
  children
}: Props) => {
  const [linkClicked, setLinkClicked] = useState(false);
  const theme = useTheme() as any;

  const handleLinkClick = () => {
    setLinkClicked(true);
  };

  const getLoadingColor = () => {
    switch (color) {
      case 'primary':
        return theme.colors.white;
      case 'outline':
        return theme.colors.dark;
      case 'red':
        return theme.colors.white;
      case 'green':
        return theme.colors.success;
      case 'disabled':
        return theme.colors.dark;
      default:
        throw Error(`Unsupported button color: ${color}`);
    }
  };

  return (
    <>
      <Container
        alignment={alignment}
        color={color}
        onClick={link ? handleLinkClick : handler}
        isLoading={isLoading}
        noPadLoad={noPadLoad}
        disabled={isLoading || disabled}
        data-tip={tooltip}
        style={style}
      >
        {isLoading && (
          <>
            <Oval
              color={getLoadingColor()}
              secondaryColor={theme.colors.light}
              width={loaderSize || 22}
              height={loaderSize || 22}
            />
            {percentage && <Percentage>{percentage}%</Percentage>}
          </>
        )}
        {children}
        {linkClicked && link ? <Navigate to={link} /> : null}
      </Container>
      {/*<ReactTooltip delayShow={500} backgroundColor={theme.colors.primary} />*/}
    </>
  );
};

export default Button;
