import { Oval } from 'react-loader-spinner';
import styled, { useTheme } from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => {
  const {
    colors: { dark, light }
  } = useTheme() as any;

  return (
    <Container>
      <Oval color={dark} secondaryColor={light} height={125} width={125} />
    </Container>
  );
};

export default PageLoader;
