import { createGlobalStyle } from 'styled-components';
import FONTS from './global/enums/fonts';

export default createGlobalStyle`
  :root {
    --toastify-color-success: #0091AE;
    --toastify-color-error: #f2545b;
    --toastify-color-info: #343A40;
    --toastify-color-warning: #ffc107;
    --contexify-zIndex: 666;
    --contexify-menu-minWidth: 220px;
    --contexify-menu-padding: 6px;
    --contexify-menu-radius: 4px;
    --contexify-menu-bgColor: #fff;
    --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), 2px 4px 4px rgba(0, 0, 0, 0.1), 3px 6px 6px rgba(0, 0, 0, 0.1);
    --contexify-menu-negatePadding: var(--contexify-menu-padding);
    --contexify-separator-color: rgba(0, 0, 0, 0.2);
    --contexify-separator-margin: 5px;
    --contexify-itemContent-padding: 6px;
    --contexify-activeItem-radius: 4px;
    --contexify-item-color: #333;
    --contexify-activeItem-color: #fff;
    --contexify-activeItem-bgColor: #0091AE;
    --contexify-rightSlot-color: #6f6e77;
    --contexify-activeRightSlot-color: #fff;
    --contexify-arrow-color: #6f6e77;
    --contexify-activeArrow-color: #fff;
  }
    body {
      color: #0A0F45;
    }
    /* Box sizing rules */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    figure,
    blockquote,
    dl,
    dd {
      margin: 0;
    }

    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
    ul[role='list'],
    ol[role='list'] {
      list-style: none;
      padding: 0;
    }

    /* Set core root defaults */
    html:focus-within {
      scroll-behavior: smooth;
    }

    /* Set core body defaults */
    body {
      text-rendering: optimizeSpeed;
      line-height: 1.5;
      font-family: ${FONTS.BODY}
    }

    h1,h2,h3,h4,h5,h6 {
      font-family: ${FONTS.HEADING}
    }

    /* A elements that don't have a class get default styles */
    a:not([class]) {
      text-decoration-skip-ink: auto;
    }

    /* Make images easier to work with */
    img,
    picture {
      max-width: 100%;
      display: block;
    }

    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
      html:focus-within {
        scroll-behavior: auto;
      }

      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
      }
    }
  
  @keyframes contexify_feedback {
    0% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  .contexify {
    position: fixed;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--contexify-menu-bgColor);
    box-sizing: border-box;
    box-shadow: var(--contexify-menu-shadow);
    border-radius: var(--contexify-menu-radius);
    padding: var(--contexify-menu-padding);
    min-width: var(--contexify-menu-minWidth);
    z-index: var(--contexify-zIndex);
  }
  .contexify_submenu-isOpen,
  .contexify_submenu-isOpen > .contexify_itemContent {
    color: var(--contexify-activeItem-color);
    background-color: var(--contexify-activeItem-bgColor);
    border-radius: var(--contexify-activeItem-radius);
  }
  .contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot {
    color: var(--contexify-activeArrow-color);
  }
  .contexify_submenu-isOpen > .contexify_submenu {
    pointer-events: auto;
    opacity: 1;
  }
  .contexify .contexify_submenu {
    position: absolute;
    pointer-events: none;
    transition: opacity 0.265s;
    top: calc(-1 * var(--contexify-menu-negatePadding));
    left: 100%;
  }
  .contexify .contexify_submenu-bottom {
    bottom: calc(-1 * var(--contexify-menu-negatePadding));
    top: unset;
  }
  .contexify .contexify_submenu-right {
    right: 100%;
    left: unset;
  }
  .contexify_rightSlot {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    color: var(--contexify-rightSlot-color);
  }
  .contexify_separator {
    height: 1px;
    cursor: default;
    margin: var(--contexify-separator-margin);
    background-color: var(--contexify-separator-color);
  }
  .contexify_willLeave-disabled {
    pointer-events: none;
  }
  .contexify_item {
    cursor: pointer;
    position: relative;
  }
  .contexify_item:focus {
    outline: 0;
  }
  .contexify_item:focus .contexify_rightSlot,
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent .contexify_rightSlot {
    color: var(--contexify-activeRightSlot-color);
  }
  .contexify_item:not(.contexify_item-disabled)[aria-haspopup] > .contexify_itemContent .contexify_rightSlot {
    color: var(--contexify-arrow-color);
  }
  .contexify_item:not(.contexify_item-disabled)[aria-haspopup].contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot,
  .contexify_item:not(.contexify_item-disabled)[aria-haspopup]:hover > .contexify_itemContent .contexify_rightSlot,
  .contexify_item[aria-haspopup]:focus > .contexify_itemContent .contexify_rightSlot {
    color: var(--contexify-activeArrow-color);
  }
  .contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent,
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent {
    color: var(--contexify-activeItem-color);
    background-color: var(--contexify-activeItem-bgColor);
    border-radius: var(--contexify-activeItem-radius);
  }
  .contexify_item:not(.contexify_item-disabled):hover > .contexify_submenu {
    pointer-events: auto;
    opacity: 1;
  }
  .contexify_item-disabled {
    cursor: default;
    opacity: 0.5;
  }
  .contexify_itemContent {
    padding: var(--contexify-itemContent-padding);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    color: var(--contexify-item-color);
    position: relative;
  }
  .contexify_item-feedback {
    animation: contexify_feedback 0.12s both;
  }
  .contexify_theme-dark {
    --contexify-menu-bgColor: rgba(40, 40, 40, 0.98);
    --contexify-separator-color: #4c4c4c;
    --contexify-item-color: #fff;
  }
  .contexify_theme-light {
    --contexify-separator-color: #eee;
    --contexify-item-color: #666;
    --contexify-activeItem-color: #3498db;
    --contexify-activeItem-bgColor: #e0eefd;
    --contexify-activeRightSlot-color: #3498db;
    --contexify-active-arrow-color: #3498db;
  }
  @keyframes contexify_scaleIn {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
      opacity: 1;
    }
  }
  @keyframes contexify_scaleOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }
  .contexify_willEnter-scale {
    transform-origin: top left;
    animation: contexify_scaleIn 0.3s;
  }
  .contexify_willLeave-scale {
    transform-origin: top left;
    animation: contexify_scaleOut 0.3s;
  }
  @keyframes contexify_fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes contexify_fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  .contexify_willEnter-fade {
    animation: contexify_fadeIn 0.3s ease;
  }
  .contexify_willLeave-fade {
    animation: contexify_fadeOut 0.3s ease;
  }
  @keyframes contexify_flipInX {
    0% {
      transform: perspective(800px) rotateX(45deg);
    }
    to {
      transform: perspective(800px);
    }
  }
  @keyframes contexify_flipOutX {
    0% {
      transform: perspective(800px);
    }
    to {
      transform: perspective(800px) rotateX(45deg);
      opacity: 0;
    }
  }
  .contexify_willEnter-flip {
    animation: contexify_flipInX 0.3s;
  }
  .contexify_willEnter-flip,
  .contexify_willLeave-flip {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    transform-origin: top center;
  }
  .contexify_willLeave-flip {
    animation: contexify_flipOutX 0.3s;
  }
  @keyframes contexify_slideIn {
    0% {
      opacity: 0;
      transform: scaleY(0.3);
    }
    to {
      opacity: 1;
    }
  }
  @keyframes contexify_slideOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scaleY(0.3);
    }
  }
  .contexify_willEnter-slide {
    transform-origin: top center;
    animation: contexify_slideIn 0.3s;
  }
  .contexify_willLeave-slide {
    transform-origin: top center;
    animation: contexify_slideOut 0.3s;
  }


`;
