import View from './View';
import React from 'react';

const Fallback = () => {
  return (
    <View>
      <></>
    </View>
  );
};

export default Fallback;
