import BillingStatus from '../enums/billingStatus';

const validStatuses = [BillingStatus.ACTIVE, BillingStatus.TRIALING];

export const hasValidSubscription = (status: string) =>
  validStatuses.includes(status);

const statusRequiresPaymentMethod = [BillingStatus.INCOMPLETE];

export const requiresPaymentMethod = (status: string) =>
  statusRequiresPaymentMethod.includes(status);

const statusNeedsSubscription = [BillingStatus.NONE, BillingStatus.CANCELlED];

export const needsSubscription = (status: string) =>
  statusNeedsSubscription.includes(status);

const statusBillingProblems = [BillingStatus.PAST_DUE];

export const hasBillingProblem = (status: string) =>
  statusBillingProblems.includes(status);

export const hasFreeTrial = (status: string) =>
  status === BillingStatus.TRIALING;

export const isCancelled = (status: string) =>
  status === BillingStatus.CANCELlED;
