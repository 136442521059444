import React from 'react';

type Props = {
  icon: string;
  className?: any;
  style?: any;
  onClick?: any;
};
const FontAwesomeIcon: React.FC<Props> = ({ icon, className, style, onClick }) => {
  return <i className={`fa fa-${icon} ${className || ''}`} style={style} onClick={onClick} />;
};

export default FontAwesomeIcon;
