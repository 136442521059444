import { createContext, useContext } from 'react';
import { Profile } from '../types';
import BillingStatus from './enums/billingStatus';

type Context = {
  isAuthenticated: boolean;
  hasProfile: boolean;
  profile: Profile;
  setProfile: (profile: Profile) => void;
  // eslint-disable-next-line no-unused-vars
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};

export const AppContext = createContext<Context>({
  isAuthenticated: false,
  hasProfile: false,
  setProfile: () => {},
  profile: {
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    billingStatus: BillingStatus.NONE,
    customerId: '',
    trialEnd: ''
  },
  login: async (email: string, password: string) => {},
  logout: async () => {}
});

export const useAppContext = () => useContext(AppContext);
