import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GoogleFontLoader from 'global/components/GoogleFontLoader';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import loadable from '@loadable/component';
import theme from 'global/theme';
import FONTS from 'global/enums/fonts';
import { AppContext } from 'global/AppContext';
import { Profile } from './types';
import BillingStatus from 'global/enums/billingStatus';
import AuthenticatedRoute from 'global/components/AuthenticatedRoute';
import PageLoader from 'global/components/PageLoader';
import Fallback from 'global/components/Fallback';
import { inspect } from '@xstate/inspect';
import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import config from './config';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/MultiRangeSlider.css';
import './assets/css/MultiRangeSliderBlack.css';
import GlobalStyles from './GlobalStyles';

const SignupView = loadable(() => import('./features/auth/views/SignupView'));
const LoginView = loadable(() => import('./features/auth/views/LoginView'));
const ForgotView = loadable(() => import('./features/auth/views/ForgotView'));
const NotFoundView = loadable(() => import('./features/auth/views/NotFoundView'));
const OnboardingView = loadable(() => import('./features/onboarding/views/OnboardingView'));
const OnboardingStatusView = loadable(
  () => import('./features/onboarding/views/OnboardingStatusView')
);
const ProfileView = loadable(() => import('./features/profile/ProfileView'), {
  fallback: <Fallback />
});
const BillingView = loadable(() => import('./billing/BillingView'), {
  fallback: <Fallback />
});
const BillingIssueView = loadable(() => import('./billing/BillingIssueView'), {
  fallback: <Fallback />
});
const ProjectsView = loadable(() => import('./features/projects/views/ProjectsView'), {
  fallback: <Fallback />
});
const ProjectView = loadable(() => import('./features/projects/views/ProjectView'), {
  fallback: <Fallback />
});
const EditorView = loadable(() => import('./features/editor/views/EditorView'), {
  fallback: <Fallback />
});
const ProjectDeleteView = loadable(() => import('./features/projects/views/ProjectDeleteView'), {
  fallback: <Fallback />
});
const TutorialsView = loadable(() => import('./features/tutorials/views/TutorialsView'), {
  fallback: <Fallback />
});
const ContactsView = loadable(() => import('./features/contacts/ContactsView'), {
  fallback: <Fallback />
});
const SessionsView = loadable(() => import('./features/sessions/views/SessionsView'), {
  fallback: <Fallback />
});

if (process.env.USE_INSPECTOR === 'true') {
  inspect({
    iframe: false // open in new window
  });
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'v1',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

const App = () => {
  const emptyProfile = {
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    billingStatus: BillingStatus.NONE,
    trialEnd: ''
  };

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [hasProfile, userHasProfile] = useState(false);
  const [profile, setProfile] = useState<Profile>(emptyProfile);

  const loadProfile = async () => {
    try {
      const apiProfile = await API.get('v1', '/profiles', {});
      if (apiProfile) {
        setProfile(apiProfile);
        userHasProfile(true);
      }
    } catch (e) {
      // user does not have a profile
    }
  };

  const logout = async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    setProfile(emptyProfile);
  };

  const login = async (email: string, password: string) => {
    await Auth.signIn(email, password);
    await loadProfile();
    userHasAuthenticated(true);
  };

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      await loadProfile();
    } catch (e) {
      // no user
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  const Logout = () => {
    useEffect(() => {
      if (isAuthenticated) {
        logout();
      }
    }, []);
    if (isAuthenticated) {
      return null;
    }
    return <Navigate to="/" />;
  };

  return (
    <>
      <GlobalStyles />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <ThemeProvider theme={theme}>
        <GoogleFontLoader
          fonts={[
            {
              font: FONTS.HEADING,
              weights: [300, 500, 600, 700]
            },
            {
              font: FONTS.BODY,
              weights: [100, 400, 700]
            }
          ]}
        />
        <AppContext.Provider
          value={{
            isAuthenticated,
            hasProfile,
            profile,
            setProfile,
            login,
            logout
          }}
        >
          {isAuthenticating ? (
            <PageLoader />
          ) : (
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/projects" />} />
                <Route path="/login" element={<LoginView />} />
                <Route path="/signup" element={<SignupView />} />
                <Route path="/forgot-password" element={<ForgotView />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/onboarding-status" element={<OnboardingStatusView />} />
                {/* Authenticated */}
                <Route
                  path="/onboarding"
                  element={
                    <AuthenticatedRoute>
                      <OnboardingView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <AuthenticatedRoute>
                      <ProfileView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <AuthenticatedRoute>
                      {' '}
                      <BillingView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/billing-issue"
                  element={
                    <AuthenticatedRoute>
                      <BillingIssueView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    <AuthenticatedRoute>
                      <ProjectsView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/projects/:projectId"
                  element={
                    <AuthenticatedRoute>
                      <ProjectView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/projects/:projectId/delete"
                  element={
                    <AuthenticatedRoute>
                      <ProjectDeleteView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/projects/:projectId/sessions"
                  element={
                    <AuthenticatedRoute>
                      <SessionsView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/projects/:projectId/:sceneId"
                  element={
                    <AuthenticatedRoute>
                      <EditorView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/tutorials"
                  element={
                    <AuthenticatedRoute>
                      <TutorialsView />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/contacts"
                  element={
                    <AuthenticatedRoute>
                      <ContactsView />
                    </AuthenticatedRoute>
                  }
                />
                <Route path="*" element={<NotFoundView />} />
              </Routes>
            </Router>
          )}
        </AppContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
