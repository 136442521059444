import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'global/components/FontAwesomeIcon';
import FONTS from '../enums/fonts';
import Button from './atomic/Button';
import Dashboard from './Dashboard';

const Container = styled.div<{ bg?: boolean; size?: string }>`
  justify-content: center;
  align-items: center;
  background-color: ${({ bg, theme }) => (bg ? theme.colors.white : 'inherit')};
  position: relative;
  padding: ${({ theme }) => `${theme.spacing.lg}`};
  height: 100vh;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet.small}) {
    max-height: -webkit-fill-available;
    width: ${({ size }) => {
      if (size) {
        switch (size) {
          case 'third': {
            return '75%';
          }
          case 'half': {
            return '50%';
          }
          case 'quarter': {
            return '25%';
          }
          default:
            return '100%';
        }
      }
    }};
  }
`;

const Entry = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Titles = styled.div``;

const BreadCrumbs = styled.ul`
  list-style-type: none;
  color: ${(props) => props.theme.colors.dark};
  display: flex;
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing.default};
  font-size: 0.85rem;
  font-weight: 100;
`;

const BreadCrumb = styled(Link)<any>`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 100;
  text-decoration: none;
`;

const CrumbDivider = styled.span`
  padding: 0px 10px;
`;

const Title = styled.h1`
  font-size: 2em;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 400;
`;

const Subtitle = styled.h4`
  margin-top: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 400;
  font-family: ${FONTS.BODY};
`;

const ButtonsContainer = styled.div<{ bottom?: boolean }>`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet.small}) {
    margin-top: ${({ theme }) => theme.spacing.default};
  }
  & > button {
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet.small}) {
      margin-left: ${({ theme }) => theme.spacing.sm};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet.small}) {
      margin-right: ${({ theme, bottom }) => (bottom ? 0 : theme.spacing.sm)};
    }
  }
  display: flex;
  align-items: stretch;
  ${({ theme, bottom }) => {
    if (bottom) {
      return `
        justify-content: flex-end;
        margin-top: ${theme.spacing.sm};
      `;
    }
  }}
`;

const Content = styled.div<{ contentBg?: boolean; padding?: string }>`
  color: ${({ theme }) => theme.colors.dark};
  ${({ contentBg, theme }) => {
    if (contentBg) {
      return `
        background-color: #fff;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        overflow: hidden;
      `;
    }
  }}
  padding: ${({ padding, theme }) => {
    if (padding) {
      switch (padding) {
        case 'xxxs': {
          return theme.spacing.xs;
        }
        case 'xxs': {
          return theme.spacing.sm;
        }
        case 'xs': {
          return theme.spacing.default;
        }
        case 'sm': {
          return theme.spacing.xl;
        }
        case 'md': {
          return theme.spacing.xxl;
        }
        default:
          return '0';
      }
    }
  }};
`;

type Props = {
  isLoading?: boolean;
  breadCrumbs?: Array<{
    label: string;
    uri: string;
  }>;
  title?: string;
  subtitle?: string;
  buttons?: Array<{
    key?: string;
    text: string;
    handler?: () => void;
    icon?: string;
    color?: string;
  }>;
  bottomButtons?: Array<{
    text: string;
    handler?: () => void;
    icon?: string;
    color?: string;
  }>;
  children: any;
  contentBg?: boolean;
  bg?: boolean;
  padding?: string;
  size?: string;
};

const View = ({
  isLoading,
  breadCrumbs,
  title,
  subtitle,
  buttons,
  bottomButtons,
  children,
  contentBg,
  bg,
  padding,
  size
}: Props) => {
  const {
    colors: { dark, light }
  } = useTheme() as any;
  if (isLoading) {
    return (
      <Container>
        <Oval color={dark} secondaryColor={light} height={50} width={50} />
      </Container>
    );
  }
  return (
    <Dashboard>
      <Container size={size} bg={bg}>
        <Entry>
          <Titles>
            {breadCrumbs && (
              <BreadCrumbs>
                <BreadCrumb to="/">
                  <FontAwesomeIcon icon="home" />
                </BreadCrumb>
                <CrumbDivider>/</CrumbDivider>
                {breadCrumbs.map((crumb) => (
                  <BreadCrumb key={crumb.label} to={crumb.uri}>
                    {crumb.label}
                    {crumb !== breadCrumbs[breadCrumbs.length - 1] ? (
                      <CrumbDivider>/</CrumbDivider>
                    ) : (
                      ''
                    )}
                  </BreadCrumb>
                ))}
              </BreadCrumbs>
            )}
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Titles>
          {buttons && (
            <ButtonsContainer>
              {buttons.map((button) => (
                <Button
                  key={button.text || button.key}
                  handler={button.handler}
                  color={button.color || ''}
                >
                  {button.icon && <FontAwesomeIcon icon={button.icon as any} />}
                  {button.text && button.text}
                </Button>
              ))}
            </ButtonsContainer>
          )}
        </Entry>
        <Content contentBg={contentBg} padding={padding}>
          {children}
        </Content>
        {bottomButtons && (
          <ButtonsContainer bottom>
            {bottomButtons.map((button) => (
              <Button key={button.text} handler={button.handler} color={button.color || ''}>
                {button.icon && <FontAwesomeIcon icon={button.icon as any} />}
                {button.text && button.text}
              </Button>
            ))}
          </ButtonsContainer>
        )}
      </Container>
    </Dashboard>
  );
};

export default View;
