import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import NavBar from './NavBar';

const Container = styled.div`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: auto;
`;

const Main = styled(motion.div)`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
`;

type Props = { children: any };
const Dashboard = ({ children }: Props) => (
  <Container>
    <Main>
      <NavBar />
      <Content>{children}</Content>
    </Main>
  </Container>
);

export default Dashboard;
